"use client";
import React, { useEffect, useRef } from "react";
import style from "./Banner.module.scss";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/all";
import { useDeviceDetector } from "@/utils/useDeviceDetector";

gsap.registerPlugin(ScrollTrigger);

interface BannerProps {
  words: any;
  variant?: boolean;
}

export const Banner = ({ words, variant }: BannerProps) => {
  const firstRowRef = useRef<HTMLDivElement | null>(null);
  const secondRowRef = useRef<HTMLDivElement | null>(null);

  const { isMobile } = useDeviceDetector();

  useEffect(() => {
    if (isMobile !== null) {
      const firstRow = Array.from(firstRowRef.current?.children || []);
      const secondRow = Array.from(secondRowRef.current?.children || []);

      const scrollTriggers = [];

      if (firstRow.length) {
        const firstRowAnimation = gsap.to(firstRow, {
          translateX: -400,
          scrollTrigger: {
            trigger: firstRowRef.current,
            start: "top bottom",
            end: "bottom top",
            scrub: 2,
          },
        });
        scrollTriggers.push(firstRowAnimation);
      }

      if (secondRow.length) {
        const secondRowAnimation = gsap.to(secondRow, {
          translateX: 400,
          scrollTrigger: {
            trigger: secondRowRef.current,
            start: "top bottom",
            end: "bottom top",
            scrub: 2,
          },
        });
        scrollTriggers.push(secondRowAnimation);
      }

      return () => {
        scrollTriggers.forEach((trigger) => {
          trigger.scrollTrigger?.kill();
          trigger.kill();
        });
      };
    }
  }, [isMobile]);
  if (!words) return null;
  const reverseWords = words.slice().reverse();

  return (
    <div
      className={`${style.wordsContainer} ${variant ? style.reduceMargin : ""}`}
    >
      <div className={style.banner} ref={firstRowRef}>
        {words.map((word, index) => (
          <span
            key={index}
            className={`${style.word} ${style[`word${index}`]}`}
          >
            {word}
          </span>
        ))}
      </div>
      <div className={`${style.banner} ${style.bannerTwo}`} ref={secondRowRef}>
        {reverseWords.map((word, index) => (
          <span
            key={index}
            className={`${style.word} ${style[`word${index}`]}`}
          >
            {word}
          </span>
        ))}
      </div>
    </div>
  );
};
