import { useFrame } from "@react-three/fiber";
import { PropsWithChildren, useRef } from "react";

export interface IFollowWaveProps {
  wave: any;
  positionOffset?: number;
  positionForce?: number;
  rotationForce?: number;
  speedFactor?: number;
  inverse?: boolean;
}

const FollowWave = ({
  children,
  wave,
  positionOffset = 0,
  positionForce = 100,
  rotationForce = 800,
  speedFactor = 1,
  inverse = false,
}: PropsWithChildren<IFollowWaveProps>) => {
  const wavePropsRef = useRef(null);
  const groupRef = useRef(null);

  useFrame(() => {
    if (wave == null) return;

    wavePropsRef.current = wave.lastChild.lastChild.attributes.d.value;
    const waveValue =
      Number.parseInt(wavePropsRef.current.split(" ")[14]) * speedFactor;

    groupRef.current.position.y =
      (inverse ? waveValue : -waveValue) / positionForce + positionOffset;
    groupRef.current.rotation.y = -waveValue / rotationForce;
    groupRef.current.rotation.x = -waveValue / rotationForce;
  });

  return <group ref={groupRef}>{children}</group>;
};

export default FollowWave;
