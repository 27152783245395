import React, { useRef } from "react";
import style from "./IsologoTransition.module.scss";
import gsap from "gsap";
import { useGSAP } from "@gsap/react";
import { useDeviceDetector } from "@/utils/useDeviceDetector";
import { Observer } from "gsap/all";

interface IsologoTransitionProps {
  extraOffset?: boolean;
  isPin?: boolean;
  isSmoothPin?: boolean;
  pinOffset?: number;
  pinCompleteDelay?: number;
}

const IsologoTransition = ({
  extraOffset = false,
  isPin = false,
  isSmoothPin = false,
  pinOffset = 140,
  pinCompleteDelay = 0.5,
}: IsologoTransitionProps) => {
  const transitionContainerRef = useRef(null);
  const logoRef = useRef<SVGSVGElement>(null);
  const logoBlinkRef = useRef<SVGSVGElement>(null);
  const logoContainerRef = useRef(null);
  const bgFrontRef = useRef(null);

  const { isMobile } = useDeviceDetector();

  const duration = 0.6;

  const deviceAnim = {
    desktop: 150,
    mobile: 500,
  };

  useGSAP(
    () => {
      let scrollObserver = null;

      if (isPin) {
        scrollObserver = Observer.create({
          type: "wheel,touch",
          wheelSpeed: -1,
          debounce: false,
          dragMinimum: 2,
          preventDefault: true,
          onEnable(self: any) {
            document.body.style.overflow = "hidden";

            const elementTop =
              logoContainerRef.current.getBoundingClientRect().top +
              window.pageYOffset;
            const targetPosition =
              elementTop -
              window.innerHeight / 2 +
              logoContainerRef.current.offsetHeight / 2 +
              pinOffset;

            const startPosition = window.pageYOffset;
            const distance = targetPosition - startPosition;

            self._restoreScroll = (e) =>
              window.scrollTo({
                top: startPosition + distance,
                behavior: isSmoothPin ? "smooth" : "instant",
              });
            document.addEventListener("scroll", self._restoreScroll, {
              passive: false,
            });
          },
          onDisable(self: any) {
            document.removeEventListener("scroll", self._restoreScroll);
            document.body.style.overflow = "";
          },
        });
        scrollObserver.disable();
      }

      const tl = gsap.timeline({
        scrollTrigger: {
          trigger: `.${style.transitionContainer}`,
          start: `top+=${window.screen.height / (extraOffset ? 3 : 2)} center`,
          end: `bottom+=50% center`,
          toggleActions: "play none none reverse",
          invalidateOnRefresh: true,
          fastScrollEnd: false,
          onEnter: (self) => {
            if (!isPin) return;

            self.scroll(self.start + 1);
            if (tl.progress() < 1) {
              scrollObserver?.enable();
            }
          },
          onLeaveBack: () => {
            gsap.set(transitionContainerRef.current, {
              pointerEvents: "all",
            });
          },
        },
        onStart: () => {
          scrollObserver?.enable();
        },
      });
      tl.addLabel("start-transition");
      tl.to(
        logoRef.current,
        {
          scale: 100,
          left: `-=${isMobile ? deviceAnim.mobile : deviceAnim.desktop}%`,
          ease: "power4.inOut",
          duration: duration,
        },
        ">"
      );
      tl.addLabel("end-transition");
      tl.to(
        [bgFrontRef.current, logoContainerRef.current],
        {
          opacity: 0,
          ease: "power4.inOut",
          duration: duration,
          onUpdate: () => {
            if (tl.progress() >= pinCompleteDelay) scrollObserver?.disable();
          },
          onComplete: () => {
            gsap.set(transitionContainerRef.current, {
              pointerEvents: "none",
            });
          },
        },
        "<"
      );
    },
    { dependencies: [isMobile], revertOnUpdate: true }
  );
  return (
    <div ref={transitionContainerRef} className={style.transitionContainer}>
      <div ref={bgFrontRef} className={style.transitionBgForward}></div>
      <div
        ref={logoContainerRef}
        className={`${style.logoContainer} ${extraOffset ? style.heightLogo : ""}`}
      >
        <svg
          width="125"
          height="211"
          viewBox="0 0 125 211"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          ref={logoRef}
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M51.0188 210.825H0.878906L74.675 105.584L0.878906 0.359375H51.0188L124.832 105.584L51.0188 210.825Z"
            fill="#17082F"
          />
        </svg>
        <svg
          width="135"
          height="37"
          viewBox="0 0 135 37"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          data-name="Blink"
          ref={logoBlinkRef}
        >
          <path
            d="M134.531 41.3359L134.531 0.132812L0.029892 0.132813L0.029892 41.3359L134.531 41.3359Z"
            fill="#17082F"
          />
        </svg>
      </div>
    </div>
  );
};

export default IsologoTransition;
