import { useEffect, useState, useMemo } from "react";

export interface BreakPointProps {
  breakpoint: [number, number];
  props: any;
}

interface ResponsiveSceneProps {
  breakPoints: Array<BreakPointProps>;
}

export const useResponsive3D = ({ breakPoints = [] }: ResponsiveSceneProps) => {
  const [breakProps, setBreakprops] = useState(null);

  const memoizedBreakPoints = useMemo(() => breakPoints, [breakPoints.length]);

  useEffect(() => {
    const handlerBreakpoint = () => {
      if (!memoizedBreakPoints || memoizedBreakPoints.length <= 0) return;

      const screenWidth = document.documentElement.clientWidth;

      let closestBreakPoint = memoizedBreakPoints.find(
        ({ breakpoint }) =>
          screenWidth >= breakpoint[0] && screenWidth <= breakpoint[1]
      );

      if (!closestBreakPoint)
        closestBreakPoint = memoizedBreakPoints[memoizedBreakPoints.length - 1];

      setBreakprops(closestBreakPoint.props);
    };

    const handlerOnResize = () => {
      handlerBreakpoint();
    };

    window.addEventListener("resize", handlerOnResize);
    handlerOnResize();

    return () => window.removeEventListener("resize", handlerOnResize);
  }, [memoizedBreakPoints]);

  return {
    breakProps,
  };
};
