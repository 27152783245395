"use client";

import React, { useState, useEffect, useRef } from "react";
import { gsap } from "gsap";
import style from "./WordsFlip.module.scss";

const WordsFlip = ({ words }) => {
  const [currentWordIndex, setCurrentWordIndex] = useState(0);
  const wordRef = useRef(null);
  useEffect(() => {
    if (!words || words.length === 0 || !wordRef.current) return;
    const tl = gsap.timeline({ repeat: -1, repeatDelay: 2 });

    tl.to(wordRef.current, {
      duration: 0.2,
      y: -20,
      opacity: 0,
      ease: "power2.inOut",
      onComplete: nextWord,
    });
    gsap.to(wordRef.current, {
      x: "0",
      scrollTrigger: {
        trigger: wordRef.current,
        start: "top bottom",
        end: "center 40%",
        scrub: 2,
      },
    });
    function nextWord() {
      setCurrentWordIndex((prevIndex) => (prevIndex + 1) % words.length);
      gsap.set(wordRef.current, { y: 20 });
      gsap.to(wordRef.current, {
        duration: 0.3,
        y: 0,
        opacity: 1,
        ease: "power2.inOut",
      });
    }

    return () => {
      tl.kill();
    };
  }, [words]);
  if (!words || words.length === 0) {
    return null;
  }
  return (
    <span ref={wordRef} className={`${style.wordFlip} title-section`}>
      {words[currentWordIndex]}
    </span>
  );
};

export default WordsFlip;
